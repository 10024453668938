import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './css/main.css'; // Ensure you have necessary styles in this file

const MainPage = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch all users from the backend
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://backenddoctorchat.rouad-althaqa.com/api/usersAdmin'); // Replace with your actual API route
        setUsers(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching users:', error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="flex min-h-screen">
      {/* Sidebar */}
      <div className="sidebar">
        <h2 className="sidebar-title">Users</h2>
        <ul className="user-list">
          {users.map((user) => (
            <li key={user.id} className="user-item">
              {user.name}
            </li>
          ))}
        </ul>
      </div>

      {/* Main Content Area */}
      <div className="main-content">
        <h1 className="main-title">Welcome to the Admin Panel</h1>
        {/* Additional content can be added here */}
      </div>
    </div>
  );
};

export default MainPage;
