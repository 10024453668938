import './App.css';
import { Route, Routes } from "react-router-dom";
import Login from './component/Login';
import Home from './component/chat/Home';
import Register from './component/Register';
import MainPage from './component/view/MainPage';
import Doctor from './component/chat/doctor/Doctor';
import UserChat from './component/chat/user/UserChat';

function App() {
  return (
    <div className="App">
      {/* Routes Component to Wrap All Route Definitions */}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/doctor" element={<Doctor />} />
        <Route path="/user" element={<UserChat />} />
        {/* <Route path="/chat" element={<Home />} /> */}
        <Route path="/register" element={<Register />} />
        <Route path="/main" element={<MainPage />} />
      </Routes>
    </div>
  );
}

export default App;
