import React, { useState, useRef, useEffect } from "react";
import "./../css/ChatWithVoice.css";
import { socket } from "../../../socket";
import axios from "axios";
import { jwtDecode } from "jwt-decode";


const decodeToken = (token) => {
    try {
        const decoded = jwtDecode(token); // Decode the token
        return decoded;
    } catch (error) {
        return null;
    }
};
const Doctor = () => {
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [activeUser, setActiveUser] = useState("");
  const recognitionRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const messagesEndRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [users, setUser] = useState([]);
  const [roomId, setRoomId] = useState();
  const [userId, setUserId] = useState();
  const [type, setTypeing] = useState("");
  const newUser=async()=>{
    const token=localStorage.getItem('token')

    const response = await axios.get('https://backenddoctorchat.rouad-althaqa.com/api/usersadmin', {
        headers: {  
            Authorization: `Bearer ${token}`, // Include the token in the header
        }        });
        setUser(response.data.joinDoctors.Details)
  }
  const fetchUserData = async (token) => {
    console.log('dsdsdsa')
    try {
        const response = await axios.get('https://backenddoctorchat.rouad-althaqa.com/api/usersadmin', {
            headers: {  
                Authorization: `Bearer ${token}`, // Include the token in the header
            }        });
            setUser(response.data.joinDoctors.Details)
            setActiveUser(response.data.joinDoctors.Details[0].userId)
            
            const tokens=localStorage.getItem('token')

              const userData = decodeToken(tokens);
              const id=userData.userId
              const roomId = [id, response.data.joinDoctors.Details[0].userId].sort().join("-");  
            setRoomId(roomId)
      const responses = await axios.get(`https://backenddoctorchat.rouad-althaqa.com/api/chat/${response.data.joinDoctors.Details[0].userId}`, {
              headers: {
                  Authorization: `Bearer ${tokens}`, // Include the token in the header
              }        });
              setMessages(responses.data.chat);

            console.log(roomId,responses,'responses','roomId')
            socket.emit("joinPrivateRoom", { user1: id, user2:  response.data.joinDoctors.Details[0].userId });


    } catch (error) {
        console.error('Error fetching user data:', error.response?.data || error.message);
    }
};
  // const users = ["John Doe", "Jane Smith", "Alice Johnson"];

  // Initialize Speech Recognition
  const startVoiceRecognition = () => {
    if (!("webkitSpeechRecognition" in window) && !("SpeechRecognition" in window)) {
      alert("Speech Recognition API is not supported in this browser.");
      return;
    }

    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();
    recognition.lang = "en-US";

    recognition.onstart = () => console.log("Voice recognition started...");
    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setCurrentMessage(transcript);
    };
    recognition.onerror = (event) => {
      console.error("Speech Recognition Error:", event.error);
    };

    recognitionRef.current = recognition;
    recognition.start();
  };
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
    // Start Voice Recording
  const startVoiceRecording = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert("Your browser does not support audio recording.");
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      const audioChunks = [];

      mediaRecorder.ondataavailable = (event) => {
        audioChunks.push(event.data);
      };

      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
        const formData = new FormData();
        formData.append("file", audioBlob);
      
        try {
      
          const response = await axios.post(
            "https://backenddoctorchat.rouad-althaqa.com/api/upload",
            formData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "multipart/form-data", // Ensure the correct header is set
              },
            }
          );
      
          const { filePath } = response.data; // Extract the uploaded file path
      
          const token = localStorage.getItem("token");
          const userData = decodeToken(token);
          const id = userData.userId;
      
          // Emit the file path to the server after successful upload
          socket.emit("sendPrivateMessage", {
            roomId,
            sender: id,
            receiver: activeUser,
            type: "voice",
            media: filePath,
            doctorId:id,
            userId:activeUser
          });
   
    socket.emit("updateUser", {userId:userData.userId})
        } catch (error) {
          console.error("Error uploading audio:", error);
        }
      };

      mediaRecorder.start();
      setIsRecording(true);
      mediaRecorderRef.current = mediaRecorder;
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  // Stop Voice Recording
  const stopVoiceRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  // Handle message sending
  const handleSend = (e) => {
    e.preventDefault();
    const token=localStorage.getItem('token')

    const userData = decodeToken(token);
    socket.emit("updateUser", {userId:userData.userId})

    const id=userData.userId
    if (currentMessage.trim()) {
      // setMessages((prevMessages) => [
      //   ...prevMessages,
      //   { type: "text", sender:id, recipient: activeUser, media: currentMessage },
      // ]);
      socket.emit("sendPrivateMessage",{ roomId:roomId, sender:id, receiver:activeUser,doctorId:id,userId:activeUser, type:"text", media:currentMessage  })
      
      setCurrentMessage("");
    }
  };

useEffect(()=>{
  fetchUserData(localStorage.getItem("token"))
  const token=localStorage.getItem('token')

  const userData = decodeToken(token);
  const id=userData.userId
  setUserId(id)
},[])
useEffect(() => {
  // Set up the socket event listener ONLY ONCE
  const handleReceiveMessage = (messageData) => {
    const token=localStorage.getItem('token')
    const userData = decodeToken(token);
    console.log(userData.userId,'a7a')
    newUser();

  socket.emit("updateUser", {userId:userData.userId})
    console.log(messageData);
    setTypeing('')
    socket.on("newUser", (data) => {
        console.log(data,'errora')
        setUser(data.joinDoctors.Details)
        setActiveUser(data.joinDoctors.Details[0].userId)
        
        // setMessages((prevMessages) => [...prevMessages, messageData]);
      });
        setMessages((prevMessages) => [...prevMessages, messageData]);
  };

  socket.on("receivePrivateMessage", handleReceiveMessage);

  // Cleanup the listener on component unmount to prevent duplicates
  return () => {
    socket.off("receivePrivateMessage", handleReceiveMessage);
  };
}, [socket]);
  useEffect(()=>{
    socket.on("newUser", (data) => {
      console.log(data,'errora')
      setUser(data.joinDoctors.Details)
      setActiveUser(data.joinDoctors.Details[0].userId)
      
      // setMessages((prevMessages) => [...prevMessages, messageData]);
    });
    console.log('newUser','errora')

  },[socket])
  useEffect(()=>{

    socket.on("error", (errors) => {
        console.log(errors,'errors4')
        // setMessages((prevMessages) => [...prevMessages, messageData]);
      });
  },[])
  useEffect(()=>{

    socket.on("errors", (errors) => {
        console.log(errors,'errors777')
        // setMessages((prevMessages) => [...prevMessages, messageData]);
      });
  },[])
  
const ChosesactiveUser=async(user)=>{
  const token=localStorage.getItem('token')
    if (user.trim()) {
      const userData = decodeToken(token);

      const responses = await axios.get(`https://backenddoctorchat.rouad-althaqa.com/api/chat/${user}`, {
        headers: {
            Authorization: `Bearer ${token}`, // Include the token in the header
        }        });
        setMessages(responses.data.chat);

        console.log(responses,'responses')
      const id=userData.userId
      const roomId = [id, user].sort().join("-");
      setRoomId(roomId)
      console.log(roomId,'roomId')
      socket.emit("joinPrivateRoom", { user1: id, user2: user });
      socket.emit("updatedState", { user1: id, user2: user });
    }

    

  setActiveUser(user)
}
let typingTimeout;

const onStartType = (user) => {
  socket.emit('StartTypeFront', { roomId, userTypeing: user });
};
const giveholiday=()=>{
    console.log('sadsad')
      socket.emit("giveing", { user:activeUser,doctorId:userId });

}
const handleTyping = (user) => {
  if (typingTimeout) clearTimeout(typingTimeout);

  typingTimeout = setTimeout(() => {
    onStartType(user);
  }, 200);
};
const handleTypeing=(data)=>{
    setTypeing(data.message)

  console.log(data,userId,'typingbacked')

}
useEffect(()=>{
  socket.on("typingbacked", handleTypeing);

  // Cleanup the listener on component unmount to prevent duplicates
  return () => {
    socket.off("typingbacked", handleTypeing);
  };
},[socket])
useEffect(()=>{
    socket.on("finshed", (data)=>{
        newUser()
    });
  
    // Cleanup the listener on component unmount to prevent duplicates
    return () => {
      socket.off("finshed");
    };
  },[socket])
  

useEffect(()=>{
    socket.on("status", (data)=>{
        newUser();
                console.log(data,'data')
    });
  
console.log('status')
  },[socket])
  
  return (
    <div className="chat-container">
      {/* Sidebar */}
      <div className="chat-sidebar">
        <h2>Active Users {}</h2>
        <ul>
          {users.map((user) => (
            <li
              key={user?.user.name}
              className={user.userId === activeUser ? "active-user" : "user-none-active"}
              onClick={() => ChosesactiveUser(user.userId)}
            >
                {console.log(user.lastMessage)}
            <div style={{ background: user.finshed === 0 ? 'red' : 'green' ,padding:'5px',borderRadius:'10px'}}>
  {user.finshed}
</div>
<div style={{ background: user.lastMessage.chat[user.lastMessage.chat.length-1].sender == user.userId ? 'gray' : '' ,padding:'5px',borderRadius:'10px'}}>
  {user.lastMessage.chat[user.lastMessage.chat.length-1].type=="text"?`${user.lastMessage.chat[user.lastMessage.chat.length-1]. media.substring(0, 5)}....`:'voicNode'}
</div>
              {user?.user.name}
            </li>
          ))}
        </ul>
      </div>

      {/* Chat Window */}
      <div className="chat-window">
        <h1>Chat with {activeUser} {type}</h1>

        <div className="messages">
          {messages
            .filter((msg) => msg.receiver === activeUser ||  msg.sender === userId && msg.receiver === userId ||  msg.sender === activeUser )
            .map((msg, index) => (
              <div
                key={index}
                className={`message ${msg.sender === userId ? "user" : "bot"}`}
              >
                <strong   className={`message ${msg.sender === userId ? "textuser" : "textbot"}`} >{msg.sender}:</strong>{" "}
                {msg.type === "text" ? (
                 <p  className={`message ${msg.sender === userId ? "usertext" : "botext"}`}>{ msg.media}</p>
                ) : (
                  <audio controls src={`https://backenddoctorchat.rouad-althaqa.com${msg.media}`}></audio>
                )}
              </div>
            ))}
              <div ref={messagesEndRef} />
        </div>
        <form className="input-container" onSubmit={handleSend}>
          <input
            type="text"
            value={currentMessage}
            onKeyUp={()=>onStartType(userId)}
            onChange={(e) => {
              handleTyping(userId);
              setCurrentMessage(e.target.value)}}
            placeholder="Type a message..."
          />
          <button type="button" onClick={startVoiceRecognition}>
            🎤
          </button>
          <button type="button" onClick={giveholiday}>
            give holiady
          </button>
          <button type="submit">Send</button>
          <button
            type="button"
            onClick={isRecording ? stopVoiceRecording : startVoiceRecording}
          >
            {isRecording ? "🛑 Stop" : "🎙️ Record"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Doctor;
